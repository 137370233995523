import Shield from 'assets/images/shield.png';

export const SecuritySection = () => {
    return (
        <section className="lg:py-[48px] py-[36px] bg-[#F3F2FF] mb-[48px]">
          <div className="container-custom">
            <h2 className="font-semibold text-center leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] lg:mb-4 mb-3">Data Privacy and Security</h2>
            <div className="leading-[150%] text-center text-[14px] text-[#45484C] lg:text-[18px] lg:mb-8 mb-6">Built into Design</div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div className="bg-white rounded-[16px] p-6 shadow-sm hover:shadow-md transition-shadow">
                    <div className="font-semibold text-[#1D2E54] text-[18px] mb-3">Isolation of workloads</div>
                    <div className="leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                        We offer DataScoop in a fully self-contained environment dedicated to your instance. We have invested in robust separation between customers, both for compute and storage.
                    </div>
                </div>

                <div className="bg-white rounded-[16px] p-6 shadow-sm hover:shadow-md transition-shadow">
                    <div className="font-semibold text-[#1D2E54] text-[18px] mb-3">Data encryption</div>
                    <div className="leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                        Data that is captured, stored or processed by DataScoop is encrypted when in transit or at rest. We also provide client side and field-level encryption of data through shared keys.
                    </div>
                </div>

                <div className="bg-white rounded-[16px] p-6 shadow-sm hover:shadow-md transition-shadow">
                    <div className="font-semibold text-[#1D2E54] text-[18px] mb-3">Cloud infrastructure</div>
                    <div className="leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                        DataScoop's services are entirely run in the cloud. All data centers are in compliance with the highest industry standards including SOC 2 Type II and ISO 27001.
                    </div>
                </div>

                <div className="bg-white rounded-[16px] p-6 shadow-sm hover:shadow-md transition-shadow">
                    <div className="font-semibold text-[#1D2E54] text-[18px] mb-3">Access Logs</div>
                    <div className="leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                        All access logs are maintained and made available to our customers for review and audit.
                    </div>
                </div>
            </div>
          </div>
        </section>
    )
}