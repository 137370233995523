import React, { useState, useEffect } from 'react';
// @ts-ignore
import Papa from 'papaparse';
import './HeatmapVisualization.css';
import { HeatMapGrid } from './components/react-grid-heatmap';
import { Spin, Form, FormInstance, Button } from 'antd';
import { ReportFormSection } from './components/ReportFormSection';
import { useFilterForm } from '../../modules/report-setup/hooks/useFilterForm';
import { useDispatch, useSelector } from 'react-redux';
import { setReport, setReportConfig, setResultTime, setResult } from '../../store/reportsSlice';
import { RootState } from '../../store';
import api from '../../utils/api';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { getHeatmapById } from './heatmapConfig';

interface CsvRow {
  id: string;
  vintage_month: string;
  vintage: string;
  as_of_month: string;
  loss_rate: string;
  yy_change: string;
  driver1: string;
  driver2: string;
  driver3: string;
  last_updated: string;
  driver4: string;
  driver5: string;
}

interface TooltipState {
  visible: boolean;
  content: React.ReactNode;
  x: number;
  y: number;
}

interface ActiveCell {
  x: number;
  y: number;
}

interface Schema {
  name: string;
  countRows: number;
  description?: string;
  fields: any[];
  previewData: any[];
}

interface HeatmapVisualizationProps {
  heatmapId?: string;
  dataSource?: string;
  form: FormInstance;
}

export function HeatmapVisualization({ heatmapId, dataSource, form }: HeatmapVisualizationProps) {
  const [xLabels, setXLabels] = useState<string[]>([]);
  const [yLabels, setYLabels] = useState<string[]>([]);
  const [data, setData] = useState<number[][]>([]);
  const [tooltip, setTooltip] = useState<TooltipState>({
    visible: false,
    content: '',
    x: 0,
    y: 0
  });
  const [columnMinMaxValues, setColumnMinMaxValues] = useState<Array<{min: number, max: number}>>([]);
  const [yyChangeData, setYYChangeData] = useState<(number | null)[][]>([]);
  const [vintageMonthData, setVintageMonthData] = useState<string[][]>([]);
  const [selectedCells, setSelectedCells] = useState<Set<string>>(new Set());
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragStart, setDragStart] = useState<{x: number, y: number} | null>(null);
  
  // New state variables for filtering
  const [lookbackPeriod, setLookbackPeriod] = useState<number>(24); // Default to 12 months
  const [filteredData, setFilteredData] = useState<number[][]>([]);
  const [filteredXLabels, setFilteredXLabels] = useState<string[]>([]);
  const [filteredYLabels, setFilteredYLabels] = useState<string[]>([]);
  const [filteredYYChangeData, setFilteredYYChangeData] = useState<(number | null)[][]>([]);
  const [vintageMonthMap, setVintageMonthMap] = useState<Map<string, Date>>(new Map());
  const [originalMonthIndices, setOriginalMonthIndices] = useState<number[]>([]);
  const [parsedData, setParsedData] = useState<CsvRow[]>([]);
  const [activeCell, setActiveCell] = useState<ActiveCell | null>(null);
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);

  // Add loading state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  // Use the dataSource prop if provided, otherwise fall back to default
  // const csvFileToUse = dataSource || csvFile;
  
  // Add form state
  const {
    updatePayload,
    updateCounter,
    resetCounter,
  } = useFilterForm({
    form,
    onSent: () => {
      // Navigation will be handled by the form section
    }
  });
  
  const dispatch = useDispatch();
  const config = useSelector((store: RootState) => store.reports.report.config);
  const schema = config?.schema as Schema | undefined;
  
  const showTooltip = (content: React.ReactNode, event: React.MouseEvent) => {
    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Default position (10px offset from cursor)
    let posX = event.clientX + 10;
    let posY = event.clientY + 10;
    
    // We'll determine the actual position when rendering, after we know the tooltip dimensions
    setTooltip({
      visible: true,
      content,
      x: event.clientX,
      y: event.clientY
    });
  };

  useEffect(() => {
    setIsLoading(true);
    
    const fetchData = async () => {
      try {
        // If a dataSource URL is provided, use it instead of the API
        if (dataSource) {
          // Fetch and parse the CSV data from the provided dataSource URL
          const response = await fetch(dataSource);
          const csvText = await response.text();
          processData(csvText);
        } else {
          console.log("🌐 Fetching data from API...");
          // Fetch data from the new API endpoint
          const response = await api.get('/api/v1/dashboard/heatmap');
          const heatmapData = response.data;
          console.log("🌐 API response received with", heatmapData.length, "rows");
          console.log("🌐 First few rows:", heatmapData.slice(0, 3));
          
          // Check for vintage_month values in the response
          const haveDates = heatmapData.every((row: any) => 
            row.vintage_month && typeof row.vintage_month === 'string');
          console.log("🌐 All rows have vintage_month values:", haveDates);
          
          // Convert API data to match the CSV format expected by the processing logic
          const csvData = heatmapData.map((row: any) => ({
            ...row,
            // Ensure all values are strings as expected by the CSV processing code
            id: String(row.id),
            vintage_month: String(row.vintage_month),
            vintage: String(row.vintage),
            as_of_month: String(row.as_of_month),
            loss_rate: row.loss_rate !== null ? String(row.loss_rate) : '',
            yy_change: row.yy_change !== null ? String(row.yy_change) : '',
          }));
          
          // Process the data without the CSV parsing step
          processApiData(csvData);
        }
      } catch (error) {
        console.error("Error fetching heatmap data:", error);
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [dataSource, heatmapId]);
  
  // Function to process CSV text data
  const processData = (csvText: string) => {
    console.log("CSV first 200 chars:", csvText.substring(0, 200));
    
    const parseResult = Papa.parse<CsvRow>(csvText, {
      header: true,
      skipEmptyLines: true
    });
    
    if (parseResult.errors && parseResult.errors.length > 0) {
      console.error("CSV parsing errors:", parseResult.errors);
    }
    
    const parsedData = parseResult.data;
    processApiData(parsedData);
  };
  
  // Function to process API data or parsed CSV data
  const processApiData = (parsedData: CsvRow[]) => {
    // Create a map of vintage to vintage_month date
    const vintageToDateMap = new Map<string, Date>();
    parsedData.forEach((row: CsvRow) => {
      if (row.vintage && row.vintage_month && !vintageToDateMap.has(row.vintage)) {
        const dateObj = new Date(row.vintage_month);
        
        if (!isNaN(dateObj.getTime())) {
          vintageToDateMap.set(row.vintage, dateObj);
        } else {
          console.warn(`Invalid date for vintage ${row.vintage}: ${row.vintage_month}`);
        }
      }
    });
    setVintageMonthMap(vintageToDateMap);

    // Extract unique vintages and convert to string array
    const uniqueVintagesSet = new Set<string>();
    parsedData.forEach((row: CsvRow) => {
      if (row.vintage) {
        uniqueVintagesSet.add(row.vintage);
      }
    });
    let uniqueVintages: string[] = Array.from(uniqueVintagesSet);
    
    // Sort vintages by their dates
    uniqueVintages.sort((a, b) => {
      const dateA = vintageToDateMap.get(a);
      const dateB = vintageToDateMap.get(b);
      if (!dateA || !dateB) return 0;
      return dateA.getTime() - dateB.getTime();
    });
    
    // Extract unique months and convert to number array
    const uniqueMonthsSet = new Set<number>();
    parsedData.forEach((row: CsvRow) => {
      if (row.as_of_month) {
        uniqueMonthsSet.add(parseInt(row.as_of_month));
      }
    });
    const uniqueMonths: number[] = Array.from(uniqueMonthsSet).sort((a, b) => a - b);

    // Create a 2D array for loss rates
    const heatmapData = uniqueVintages.map((vintage) =>
      uniqueMonths.map((month) => {
        const record = parsedData.find(
          (row: CsvRow) => row.vintage === vintage && parseInt(row.as_of_month) === month
        );
        return record ? parseFloat(record.loss_rate) : 0;
      })
    );

    // Create a 2D array for Y/Y change values
    const yyChangeData = uniqueVintages.map((vintage) =>
      uniqueMonths.map((month) => {
        const record = parsedData.find(
          (row: CsvRow) => row.vintage === vintage && parseInt(row.as_of_month) === month
        );
        return record && record.yy_change ? parseFloat(record.yy_change) : null;
      })
    );

    // Create a 2D array for VINTAGE_MONTH values
    const vintageMonthData = uniqueVintages.map((vintage) =>
      uniqueMonths.map((month) => {
        const record = parsedData.find(
          (row: CsvRow) => row.vintage === vintage && parseInt(row.as_of_month) === month
        );
        return record ? record.vintage_month : '';
      })
    );

    // Calculate min and max values for each month
    const monthMinMaxValues = uniqueMonths.map((_, monthIndex) => {
      const monthValues = uniqueVintages.map((_, vintageIndex) => 
        heatmapData[vintageIndex][monthIndex]
      ).filter(value => value > 0);
      
      if (monthValues.length === 0) return { min: 0, max: 0 };
      
      return {
        min: Math.min(...monthValues),
        max: Math.max(...monthValues)
      };
    });

    // Set states
    setXLabels(uniqueVintages);
    setYLabels(uniqueMonths.map((m) => `Month ${m}`));
    setData(heatmapData);
    setYYChangeData(yyChangeData);
    setVintageMonthData(vintageMonthData);
    setColumnMinMaxValues(monthMinMaxValues);
    
    // Apply initial filtering with sorted vintages
    applyLookbackFilter(uniqueVintages, heatmapData, yyChangeData, vintageToDateMap, lookbackPeriod);
    setParsedData(parsedData);
    
    // Set loading to false when done
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchReport = async () => {
      try {
        if (!heatmapId) {
          console.error('Heatmap ID is required');
          return;
        }
        const heatmapConfig = getHeatmapById(heatmapId);
        if (!heatmapConfig) {
          console.error('Heatmap configuration not found');
          return;
        }
        const reportId = heatmapConfig.reportId;
        const response = await api.get(`/api/v1/reports/${reportId}`);
        const reportData = response.data;
        
        // Parse the report_config JSON string
        if (reportData.report_config) {
          const parsedConfig = JSON.parse(reportData.report_config);
          // Update the report data with parsed config
          reportData.config = parsedConfig;
        }
        
        dispatch(setReport(reportData));
      } catch (error) {
        console.error('Error fetching report:', error);
      }
    };

    fetchReport();
  }, [dispatch, heatmapId]);

  // Function to apply lookback period filter
  const applyLookbackFilter = (
    allVintages: string[], 
    fullData: number[][], 
    fullYYChangeData: (number | null)[][],
    vintageMap: Map<string, Date>,
    months: number
  ) => {
    // If months is very large or we want all data, just use the full dataset
    if (months >= 999 || months <= 0) {
      setFilteredData(fullData);
      setFilteredXLabels(allVintages);
      setFilteredYLabels(yLabels);
      setFilteredYYChangeData(fullYYChangeData);
      setOriginalMonthIndices(yLabels.map((_, i) => i));
      return;
    }

    // Find the most recent vintage date
    let maxDate: Date | null = null;
    vintageMap.forEach((date) => {
      if (!maxDate || date > maxDate) {
        maxDate = date;
      }
    });
    
    if (!maxDate) {
      // No valid dates, use all data
      setFilteredData(fullData);
      setFilteredXLabels(allVintages);
      setFilteredYLabels(yLabels);
      setFilteredYYChangeData(fullYYChangeData);
      setOriginalMonthIndices(yLabels.map((_, i) => i));
      return;
    }
    
    // Calculate the cutoff date
    const cutoffDate = new Date(maxDate);
    cutoffDate.setMonth(cutoffDate.getMonth() - months);
    
    // Filter vintages based on the cutoff date
    const filteredVintageIndices: number[] = [];
    const filteredVintages: string[] = [];
    
    allVintages.forEach((vintage, index) => {
      const vintageDate = vintageMap.get(vintage);
      if (vintageDate && vintageDate >= cutoffDate) {
        filteredVintageIndices.push(index);
        filteredVintages.push(vintage);
      }
    });
    
    // If no vintages match the filter, use all data
    if (filteredVintages.length === 0) {
      setFilteredData(fullData);
      setFilteredXLabels(allVintages);
      setFilteredYLabels(yLabels);
      setFilteredYYChangeData(fullYYChangeData);
      setOriginalMonthIndices(yLabels.map((_, i) => i));
      return;
    }
    
    // Find which months (y-axis) have data in the filtered vintages
    const monthsWithData = new Set<number>();
    
    for (const vintageIndex of filteredVintageIndices) {
      for (let monthIndex = 0; monthIndex < fullData[vintageIndex].length; monthIndex++) {
        if (fullData[vintageIndex][monthIndex] > 0) {
          monthsWithData.add(monthIndex);
        }
      }
    }
    
    // Convert to sorted array of month indices
    const activeMonthIndices = Array.from(monthsWithData).sort((a, b) => a - b);
    
    // Create filtered y-labels
    const filteredMonthLabels = activeMonthIndices.map(index => yLabels[index]);
    
    // Create new data arrays with only the filtered vintages and months
    const newFilteredData: number[][] = [];
    const newFilteredYYChangeData: (number | null)[][] = [];
    
    // For each filtered vintage
    for (const vintageIndex of filteredVintageIndices) {
      const dataRow: number[] = [];
      const yyChangeRow: (number | null)[] = [];
      
      // For each active month
      for (const monthIndex of activeMonthIndices) {
        dataRow.push(fullData[vintageIndex][monthIndex]);
        yyChangeRow.push(fullYYChangeData[vintageIndex][monthIndex]);
      }
      
      newFilteredData.push(dataRow);
      newFilteredYYChangeData.push(yyChangeRow);
    }
    
    // Update state with filtered data
    setFilteredData(newFilteredData);
    setFilteredXLabels(filteredVintages);
    setFilteredYLabels(filteredMonthLabels);
    setFilteredYYChangeData(newFilteredYYChangeData);
    setOriginalMonthIndices(activeMonthIndices);
    
    // Clear selection when filter changes
    setSelectedCells(new Set());
  };

  // Separate effect to handle lookback period changes
  useEffect(() => {
    // Only apply filter if data has been loaded
    if (xLabels.length > 0 && data.length > 0 && vintageMonthMap.size > 0) {
      applyLookbackFilter(xLabels, data, yyChangeData, vintageMonthMap, lookbackPeriod);
    }
  }, [lookbackPeriod, xLabels, data, yyChangeData, vintageMonthMap]);

  // Add function to update form values based on selected cells
  const updateFormValues = (selectedCellKeys: Set<string>) => {
    if (selectedCellKeys.size === 0) return;

    // Get all selected cells' data
    const selectedCellsData = Array.from(selectedCellKeys).map(coord => {
      const [x, y] = coord.split(',').map(Number);
      const vintageMonth = filteredXLabels[y];
      const asOfMonth = parseInt(filteredYLabels[x].replace('Month ', ''));
      
      // Find the actual record to get the proper vintage_month
      const record = parsedData.find(
        (row: CsvRow) => row.vintage === vintageMonth && parseInt(row.as_of_month) === asOfMonth
      );

      return {
        vintageMonth: record?.vintage_month || vintageMonth,
        asOfMonth
      };
    });

    // Filter out any cells with invalid dates
    const validCellsData = selectedCellsData.filter(cell => {
      // Create date in UTC by appending 'T00:00:00Z'
      const date = new Date(`${cell.vintageMonth}T00:00:00Z`);
      return !isNaN(date.getTime());
    });

    if (validCellsData.length === 0) return;

    // Calculate date ranges in UTC
    const vintageMonths = validCellsData.map(cell => {
      // Create date in UTC by appending 'T00:00:00Z'
      return new Date(`${cell.vintageMonth}T00:00:00Z`);
    });
    
    const minVintageMonth = new Date(Math.min(...vintageMonths.map(d => d.getTime())));
    const maxVintageMonth = new Date(Math.max(...vintageMonths.map(d => d.getTime())));

    // Calculate analyze date range in UTC
    const analyzeStartDate = new Date(Date.UTC(minVintageMonth.getUTCFullYear(), minVintageMonth.getUTCMonth(), 1));
    const analyzeEndDate = new Date(Date.UTC(maxVintageMonth.getUTCFullYear(), maxVintageMonth.getUTCMonth() + 1, 0));

    // Calculate compare date range in UTC (1 year before)
    const compareStartDate = new Date(Date.UTC(minVintageMonth.getUTCFullYear() - 1, minVintageMonth.getUTCMonth(), 1));
    const compareEndDate = new Date(Date.UTC(maxVintageMonth.getUTCFullYear() - 1, maxVintageMonth.getUTCMonth() + 1, 0));

    // Calculate max as_of_month for filter
    const maxAsOfMonth = Math.max(...validCellsData.map(cell => cell.asOfMonth));

    // Get all current form values
    const currentFormValues = form.getFieldsValue();

    // Get the current ratio field value to preserve other settings
    const currentRatio = currentFormValues.ratio || {};

    // Create the new ratio object
    const newRatio = {
      ...currentRatio,
      numFilters: [{
        column: 'months_to_default',
        operator: 'lt',
        columnsToCompare: [maxAsOfMonth + 1]
      }]
    };

    // Create the complete form values object
    const formValues = {
      ...currentFormValues, // Preserve all existing form values
      analyzeDate: [dayjs(analyzeStartDate).utc(), dayjs(analyzeEndDate).utc()],
      compareDate: [dayjs(compareStartDate).utc(), dayjs(compareEndDate).utc()],
      ratio: newRatio
    };

    // Set form values with a delay to ensure the form is ready
    setTimeout(() => {
      form.setFieldsValue(formValues);
    }, 100);
  };

  // Add keyboard event handlers
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Handle Shift key press
      if (e.key === 'Shift') {
        setIsShiftPressed(true);
        return;
      }

      // Handle arrow keys with shift
      if (isShiftPressed && activeCell) {
        let newX = activeCell.x;
        let newY = activeCell.y;

        switch (e.key) {
          case 'ArrowUp':
            e.preventDefault();
            newX = Math.max(0, activeCell.x - 1);
            break;
          case 'ArrowDown':
            e.preventDefault();
            newX = Math.min(filteredYLabels.length - 1, activeCell.x + 1);
            break;
          case 'ArrowLeft':
            e.preventDefault();
            newY = Math.max(0, activeCell.y - 1);
            break;
          case 'ArrowRight':
            e.preventDefault();
            newY = Math.min(filteredXLabels.length - 1, activeCell.y + 1);
            break;
          default:
            return;
        }

        // Only proceed if we're moving to a valid cell with data
        if (filteredData[newY] && filteredData[newY][newX] > 0) {
          // Create a new selection that includes all cells between the original active cell
          // and the new position
          const newSelectedCells = new Set(selectedCells);
          
          // Calculate the range to select
          const minX = Math.min(activeCell.x, newX);
          const maxX = Math.max(activeCell.x, newX);
          const minY = Math.min(activeCell.y, newY);
          const maxY = Math.max(activeCell.y, newY);

          // Add all cells in the range
          for (let i = minX; i <= maxX; i++) {
            for (let j = minY; j <= maxY; j++) {
              if (filteredData[j] && filteredData[j][i] > 0) {
                newSelectedCells.add(`${i},${j}`);
              }
            }
          }

          setSelectedCells(newSelectedCells);
          setActiveCell({ x: newX, y: newY });
          updateFormValues(newSelectedCells);

          // Update tooltip content for multiple cell selection
          if (newSelectedCells.size > 1) {
            const selectedCoords = Array.from(newSelectedCells).map(coord => {
              const [x, y] = coord.split(',').map(Number);
              return { x, y };
            });
            
            const commonDrivers = findCommonDrivers(selectedCoords);
            
            if (commonDrivers.length > 0) {
              const content = (
                <>
                  <div style={{ 
                    marginBottom: '12px', 
                    fontWeight: 'bold',
                    fontSize: '16px',
                    borderBottom: '1px solid rgba(255,255,255,0.3)',
                    paddingBottom: '8px'
                  }}>
                    Common Top Drivers ({newSelectedCells.size} cells)
                  </div>
                  
                  <div style={{ marginLeft: '8px' }}>
                    {commonDrivers.slice(0, 5).map((driver, index) => (
                      <div key={index} style={{ marginBottom: '10px' }}>
                        <span style={{ fontWeight: 'bold', marginRight: '4px' }}>{index + 1}.</span> 
                        {driver.driver} 
                        <span style={{ 
                          fontSize: '12px', 
                          color: '#aaa', 
                          marginLeft: '6px' 
                        }}>
                          ({driver.count}/{newSelectedCells.size} cells)
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              );
              
              // Get the current mouse position or use a default position
              const lastEvent = window.event as MouseEvent;
              if (lastEvent) {
                showTooltip(content, {
                  clientX: lastEvent.clientX,
                  clientY: lastEvent.clientY,
                  stopPropagation: () => {}
                } as React.MouseEvent);
              } else {
                // If no mouse event, show tooltip at a default position
                showTooltip(content, {
                  clientX: window.innerWidth / 2,
                  clientY: window.innerHeight / 2,
                  stopPropagation: () => {}
                } as React.MouseEvent);
              }
            } else {
              const content = (
                <>
                  <div style={{ 
                    marginBottom: '12px', 
                    fontWeight: 'bold',
                    fontSize: '16px',
                    borderBottom: '1px solid rgba(255,255,255,0.3)',
                    paddingBottom: '8px'
                  }}>
                    Common Top Drivers ({newSelectedCells.size} cells)
                  </div>
                  
                  <div style={{ 
                    marginLeft: '8px', 
                    fontStyle: 'italic', 
                    color: '#aaa',
                    padding: '10px 0'
                  }}>
                    No common drivers found across selected cells
                  </div>
                </>
              );
              
              // Get the current mouse position or use a default position
              const lastEvent = window.event as MouseEvent;
              if (lastEvent) {
                showTooltip(content, {
                  clientX: lastEvent.clientX,
                  clientY: lastEvent.clientY,
                  stopPropagation: () => {}
                } as React.MouseEvent);
              } else {
                // If no mouse event, show tooltip at a default position
                showTooltip(content, {
                  clientX: window.innerWidth / 2,
                  clientY: window.innerHeight / 2,
                  stopPropagation: () => {}
                } as React.MouseEvent);
              }
            }
          }
        }
      }

      // Handle Escape key
      if (e.key === 'Escape') {
        setSelectedCells(new Set());
        setTooltip(prev => ({ ...prev, visible: false }));
        setActiveCell(null);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setIsShiftPressed(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [activeCell, selectedCells, filteredData, filteredXLabels, filteredYLabels, isShiftPressed]);

  // Modify handleMouseDown to set activeCell
  const handleMouseDown = (x: number, y: number, e: React.MouseEvent) => {
    e.preventDefault();
    
    // If shift is pressed and we have an active cell, do rectangular selection
    if (e.shiftKey && activeCell) {
      const newSelectedCells = new Set<string>();
      
      // Calculate the range to select
      const minX = Math.min(activeCell.x, x);
      const maxX = Math.max(activeCell.x, x);
      const minY = Math.min(activeCell.y, y);
      const maxY = Math.max(activeCell.y, y);
      
      // Add all cells in the range
      for (let i = minX; i <= maxX; i++) {
        for (let j = minY; j <= maxY; j++) {
          if (filteredData[j] && filteredData[j][i] > 0) {
            newSelectedCells.add(`${i},${j}`);
          }
        }
      }
      
      setSelectedCells(newSelectedCells);
      updateFormValues(newSelectedCells);

      // Update tooltip content for multiple cell selection
      if (newSelectedCells.size > 1) {
        const selectedCoords = Array.from(newSelectedCells).map(coord => {
          const [x, y] = coord.split(',').map(Number);
          return { x, y };
        });
        
        const commonDrivers = findCommonDrivers(selectedCoords);
        
        if (commonDrivers.length > 0) {
          const content = (
            <>
              <div style={{ 
                marginBottom: '12px', 
                fontWeight: 'bold',
                fontSize: '16px',
                borderBottom: '1px solid rgba(255,255,255,0.3)',
                paddingBottom: '8px'
              }}>
                Common Top Drivers ({newSelectedCells.size} cells)
              </div>
              
              <div style={{ marginLeft: '8px' }}>
                {commonDrivers.slice(0, 5).map((driver, index) => (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <span style={{ fontWeight: 'bold', marginRight: '4px' }}>{index + 1}.</span> 
                    {driver.driver} 
                    <span style={{ 
                      fontSize: '12px', 
                      color: '#aaa', 
                      marginLeft: '6px' 
                    }}>
                      ({driver.count}/{newSelectedCells.size} cells)
                    </span>
                  </div>
                ))}
              </div>
            </>
          );
          
          showTooltip(content, e);
        } else {
          const content = (
            <>
              <div style={{ 
                marginBottom: '12px', 
                fontWeight: 'bold',
                fontSize: '16px',
                borderBottom: '1px solid rgba(255,255,255,0.3)',
                paddingBottom: '8px'
              }}>
                Common Top Drivers ({newSelectedCells.size} cells)
              </div>
              
              <div style={{ 
                marginLeft: '8px', 
                fontStyle: 'italic', 
                color: '#aaa',
                padding: '10px 0'
              }}>
                No common drivers found across selected cells
              </div>
            </>
          );
          
          showTooltip(content, e);
        }
      }
      return;
    }

    // Regular click behavior (no shift)
    setIsDragging(true);
    setDragStart({x, y});
    
    const cellKey = `${x},${y}`;
    const newSelectedCells = new Set(selectedCells);
    
    if (e.ctrlKey || e.metaKey) {
      if (newSelectedCells.has(cellKey)) {
        newSelectedCells.delete(cellKey);
      } else {
        newSelectedCells.add(cellKey);
      }
    } else {
      newSelectedCells.clear();
      newSelectedCells.add(cellKey);
    }
    
    setSelectedCells(newSelectedCells);
    setActiveCell({ x, y }); // Set the active cell when clicking
    updateFormValues(newSelectedCells);
  };

  const handleMouseMove = (x: number, y: number, e: React.MouseEvent) => {
    if (isDragging && dragStart) {
      e.preventDefault();
      
      // Calculate the range to select
      const minX = Math.min(dragStart.x, x);
      const maxX = Math.max(dragStart.x, x);
      const minY = Math.min(dragStart.y, y);
      const maxY = Math.max(dragStart.y, y);
      
      // Create a new set of selected cells
      const newSelectedCells = new Set<string>();
      
      // Add all cells in the rectangular range to the selection
      for (let i = minX; i <= maxX; i++) {
        for (let j = minY; j <= maxY; j++) {
          if (j < filteredData.length && 
              i < filteredData[j].length && 
              filteredData[j][i] > 0) {
            newSelectedCells.add(`${i},${j}`);
          }
        }
      }
      
      setSelectedCells(newSelectedCells);
      updateFormValues(newSelectedCells);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStart(null);
    
    // If multiple cells are selected after dragging, show the tooltip
    if (selectedCells.size > 1) {
      // Get coordinates of all selected cells
      const selectedCoords = Array.from(selectedCells).map(coord => {
        const [x, y] = coord.split(',').map(Number);
        return { x, y };
      });
      
      // Find common drivers across any position
      const commonDrivers = findCommonDrivers(selectedCoords);
      
      // Only show tooltip if there are common drivers
      if (commonDrivers.length > 0) {
        // Create content for multiple cell selection
        const content = (
          <>
            <div style={{ 
              marginBottom: '12px', 
              fontWeight: 'bold',
              fontSize: '16px',
              borderBottom: '1px solid rgba(255,255,255,0.3)',
              paddingBottom: '8px'
            }}>
              Common Top Drivers ({selectedCells.size} cells)
            </div>
            
            <div style={{ marginLeft: '8px' }}>
              {commonDrivers.slice(0, 5).map((driver, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                  <span style={{ fontWeight: 'bold', marginRight: '4px' }}>{index + 1}.</span> 
                  {driver.driver} 
                  <span style={{ 
                    fontSize: '12px', 
                    color: '#aaa', 
                    marginLeft: '6px' 
                  }}>
                    ({driver.count}/{selectedCells.size} cells)
                  </span>
                </div>
              ))}
            </div>
          </>
        );
        
        // Get the last mouse position from the drag event
        const lastEvent = window.event as MouseEvent;
        if (lastEvent) {
          showTooltip(content, {
            clientX: lastEvent.clientX,
            clientY: lastEvent.clientY,
            stopPropagation: () => {}
          } as React.MouseEvent);
        }
      } else {
        // If no common drivers, show a message
        const content = (
          <>
            <div style={{ 
              marginBottom: '12px', 
              fontWeight: 'bold',
              fontSize: '16px',
              borderBottom: '1px solid rgba(255,255,255,0.3)',
              paddingBottom: '8px'
            }}>
              Common Top Drivers ({selectedCells.size} cells)
            </div>
            
            <div style={{ 
              marginLeft: '8px', 
              fontStyle: 'italic', 
              color: '#aaa',
              padding: '10px 0'
            }}>
              No common drivers found across selected cells
            </div>
          </>
        );
        
        // Get the last mouse position from the drag event
        const lastEvent = window.event as MouseEvent;
        if (lastEvent) {
          showTooltip(content, {
            clientX: lastEvent.clientX,
            clientY: lastEvent.clientY,
            stopPropagation: () => {}
          } as React.MouseEvent);
        }
      }
    }
  };

  // Add document-level mouse up handler
  useEffect(() => {
    const handleDocumentMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
        setDragStart(null);
      }
    };
    
    document.addEventListener('mouseup', handleDocumentMouseUp);
    
    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp);
    };
  }, [isDragging]);

  // Handle clicks outside the heatmap
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Don't clear selection if we're submitting
      if (isSubmitting) return;
      
      const heatmapElement = document.querySelector('.heatmap-container');
      if (heatmapElement && !heatmapElement.contains(e.target as Node)) {
        setTooltip(prev => ({ ...prev, visible: false }));
        setSelectedCells(new Set());
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSubmitting]);

  // Add this function to find common drivers across multiple cells
  const findCommonDrivers = (selectedCoords: Array<{x: number, y: number}>) => {
    // Initialize a map to count occurrences of each unique driver
    const driverCounts: Record<string, number> = {};
    
    // Extract all drivers from each selected cell
    selectedCoords.forEach(({x, y}) => {
      const cellVintage = filteredXLabels[y];
      const cellMonth = parseInt(filteredYLabels[x].replace('Month ', ''));
      
      const record = parsedData.find(
        (row: CsvRow) => row.vintage === cellVintage && parseInt(row.as_of_month) === cellMonth
      );
      
      if (record) {
        // Add all drivers from this cell to the count map
        const allDrivers = [
          record.driver1, 
          record.driver2, 
          record.driver3, 
          record.driver4, 
          record.driver5
        ].filter(Boolean); // Remove any undefined/null/empty drivers
        
        // Count each unique driver
        allDrivers.forEach(driver => {
          if (driver) {
            driverCounts[driver] = (driverCounts[driver] || 0) + 1;
          }
        });
      }
    });
    
    // Get drivers that appear in more than one cell
    const commonDrivers = Object.entries(driverCounts)
      .filter(([_, count]) => count > 1) // Only include drivers that appear in more than one cell
      .sort((a, b) => b[1] - a[1]) // Sort by frequency (most common first)
      .map(([driver, count]) => ({ driver, count }));
    
    return commonDrivers;
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const payload = await updatePayload({}, {});
      if (payload && 'isError' in payload && payload.isError) {
        setIsSubmitting(false);
        return;
      }

      // Get form values
      const formValues = form.getFieldsValue();

      // Add fileId from schema name and include settings
      const finalPayload = {
        ...payload,
        fileId: schema?.name || ''
      };

      // Create the report config with settings
      const reportConfig = {
        ...config,
        settings: formValues,
        rawPayload: finalPayload
      };

      // Send data to API using the api utility
      const response = await api.post('/api/v1/insight/file/metric', finalPayload);
      const data = response.data;
      
      // Update Redux store with both the result and the report config
      dispatch(setResultTime(data.resultTime));
      dispatch(setReportConfig(reportConfig));
      dispatch(setResult(data));

      // Navigate to discover-segments
      navigate('/discover-segments');
    } catch (error) {
      console.error('Error submitting form:', error);
      setIsSubmitting(false);
    }
  };

  const renderExplainDriversButton = () => {
    // Only hide the button if there are no selected cells and we're not submitting
    if (selectedCells.size === 0 && !isSubmitting) return null;
    
    return (
      <div style={{ 
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center", 
        gap: "12px" 
      }}>
        <Spin spinning={isSubmitting}>
          <a 
            onClick={handleSubmit}
            style={{
              backgroundColor: "#445082",
              color: "white",
              padding: "8px 16px",
              borderRadius: "4px",
              border: "none",
              textDecoration: "none",
              fontWeight: "bold",
              cursor: isSubmitting ? "not-allowed" : "pointer",
              display: "inline-block",
              opacity: isSubmitting ? 0.5 : 1,
              minWidth: "144px",
              textAlign: "center"
            }}
          >
            {isSubmitting ? "Processing..." : "Explain Drivers"}
          </a>
        </Spin>
        
        <div style={{ 
          fontSize: "14px", 
          color: "#666",
          fontStyle: "italic"
        }}>
          {selectedCells.size === 1 
            ? "1 cell selected" 
            : `${selectedCells.size} cells selected`}
        </div>
      </div>
    );
  };

  return (
    <div className="heatmap-app" style={{ position: 'relative' }}>
      {isLoading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
          width: '100%'
        }}>
          <Spin size="large" tip="Loading heatmap data..." />
        </div>
      ) : (
        <div style={{ textAlign: "center", width: "100%", padding: "20px 0" }}>
          <div style={{ 
            marginBottom: "20px", 
            display: "flex", 
            alignItems: "center", 
            justifyContent: "center",
            gap: "20px"
          }}>
            <div style={{ 
              display: "flex", 
              alignItems: "center",
              gap: "10px"
            }}>
              <label htmlFor="lookbackPeriod" style={{ fontWeight: "bold", color: "#445082" }}>
                Lookback Period:
              </label>
              <select 
                id="lookbackPeriod"
                value={lookbackPeriod}
                onChange={(e) => {
                  const newValue = parseInt(e.target.value);
                  console.log("⏱️ Lookback period changed from", lookbackPeriod, "to", newValue);
                  setLookbackPeriod(newValue);
                }}
                style={{
                  padding: "8px 12px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  backgroundColor: "#fff",
                  fontSize: "14px",
                  cursor: "pointer"
                }}
              >
                <option value="3">Last 3 months</option>
                <option value="6">Last 6 months</option>
                <option value="12">Last 12 months</option>
                <option value="24">Last 24 months</option>
                <option value="36">Last 36 months</option>
                <option value="48">Last 48 months</option>
                <option value="60">Last 60 months</option>
                <option value="72">Last 72 months</option>
                <option value="999">All time</option>
              </select>
            </div>

            {vintageMonthMap.size > 0 && filteredXLabels.length > 0 && (
              <div style={{ 
                fontSize: "14px", 
                color: "#666",
                fontStyle: "italic"
              }}>
                {(() => {
                  // Find the date range based on the filtered data
                  let minDate: Date | null = null;
                  let maxDate: Date | null = null;
                  
                  filteredXLabels.forEach(vintage => {
                    const date = vintageMonthMap.get(vintage);
                    if (date) {
                      if (!minDate || date < minDate) minDate = date;
                      if (!maxDate || date > maxDate) maxDate = date;
                    }
                  });
                  
                  if (!minDate || !maxDate) return "";
                  
                  const formatDate = (date: Date) => {
                    // Format the date in UTC to avoid timezone issues
                    return date.toLocaleDateString('en-US', { 
                      year: 'numeric', 
                      month: 'short',
                      timeZone: 'UTC' // Force UTC timezone for formatting
                    });
                  };
                  
                  return `(${formatDate(minDate)} to ${formatDate(maxDate)})`;
                })()}
              </div>
            )}

            {renderExplainDriversButton()}
          </div>
      
          <div style={{ flexDirection: "column", alignItems: "center", gap: "10px" }}>
            <div style={{ 
              fontSize: "0.85rem", 
              color: "#666", 
              fontStyle: "italic",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "6px"
            }}>
              <div style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                backgroundColor: "#445082",
                color: "white",
                fontSize: "10px",
                fontWeight: "bold"
              }}>
                i
              </div>
              Single-click: View specific loss drivers • Click and drag: Identify shared drivers across cells
            </div>
      
            <div style={{ 
              width: "100%", 
              height: "1px", 
              background: "linear-gradient(to right, transparent, #E6E6E7, transparent)", 
              marginBottom: "15px" 
            }}></div>
      
            <div style={{ fontSize: "1.2rem", fontWeight: "bold", marginBottom: "15px", color: "#445082" }}>
              Vintage
            </div>
      
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div style={{ 
                fontSize: "1.2rem", 
                fontWeight: "bold", 
                transform: "rotate(-90deg)", 
                whiteSpace: "nowrap",
                color: "#445082"
              }}>
                As of Month
              </div>
      
              <div className="heatmap-container" style={{ padding: "10px" }}>
                <HeatMapGrid
                  data={filteredData}
                  xLabels={filteredXLabels}
                  yLabels={filteredYLabels}
                  cellRender={(x, y, value) =>
                    value === 0 ? <div></div> : (
                      <div 
                        title={`Vintage: ${filteredXLabels[y]}, As of: ${filteredYLabels[x]}, Loss Rate: ${(value * 100).toFixed(2)}%`}
                        onMouseDown={(e) => handleMouseDown(x, y, e)}
                        onMouseMove={(e) => handleMouseMove(x, y, e)}
                        onMouseUp={handleMouseUp}
                        onClick={(e) => {
                          e.stopPropagation();
                          
                          // Check if multiple cells are selected
                          if (selectedCells.size > 1) {
                            // Get coordinates of all selected cells
                            const selectedCoords = Array.from(selectedCells).map(coord => {
                              const [x, y] = coord.split(',').map(Number);
                              return { x, y };
                            });
                            
                            // Find common drivers across any position
                            const commonDrivers = findCommonDrivers(selectedCoords);
                            
                            // Only show tooltip if there are common drivers
                            if (commonDrivers.length > 0) {
                              // Create content for multiple cell selection
                              const content = (
                                <>
                                  <div style={{ 
                                    marginBottom: '12px', 
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    borderBottom: '1px solid rgba(255,255,255,0.3)',
                                    paddingBottom: '8px'
                                  }}>
                                    Common Top Drivers ({selectedCells.size} cells)
                                  </div>
                                  
                                  <div style={{ marginLeft: '8px' }}>
                                    {commonDrivers.slice(0, 5).map((driver, index) => (
                                      <div key={index} style={{ marginBottom: '10px' }}>
                                        <span style={{ fontWeight: 'bold', marginRight: '4px' }}>{index + 1}.</span> 
                                        {driver.driver} 
                                        <span style={{ 
                                          fontSize: '12px', 
                                          color: '#aaa', 
                                          marginLeft: '6px' 
                                        }}>
                                          ({driver.count}/{selectedCells.size} cells)
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              );
                              
                              showTooltip(content, e);
                            } else {
                              // If no common drivers, show a message
                              const content = (
                                <>
                                  <div style={{ 
                                    marginBottom: '12px', 
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    borderBottom: '1px solid rgba(255,255,255,0.3)',
                                    paddingBottom: '8px'
                                  }}>
                                    Common Top Drivers ({selectedCells.size} cells)
                                  </div>
                                  
                                  <div style={{ 
                                    marginLeft: '8px', 
                                    fontStyle: 'italic', 
                                    color: '#aaa',
                                    padding: '10px 0'
                                  }}>
                                    No common drivers found across selected cells
                                  </div>
                                </>
                              );
                              
                              showTooltip(content, e);
                            }
                          } else {
                            // Existing code for single cell tooltip
                            const yyChange = filteredYYChangeData[y] && filteredYYChangeData[y][x];
                            let yyChangeDisplay = 'N/A';
                            let yyChangeStyle = {};
                            
                            if (yyChange !== null) {
                              // For loss rates: positive change (increase) is bad, negative change (decrease) is good
                              if (yyChange > 0) {
                                // Positive change (bad) - show in red with + sign
                                yyChangeDisplay = `+${(yyChange * 100).toFixed(2)}%`;
                                yyChangeStyle = {
                                  color: '#F44336', // Red for positive (bad)
                                  fontWeight: 'bold'
                                };
                              } else if (yyChange < 0) {
                                // Negative change (good) - show in green with - sign
                                // Math.abs to remove the negative sign, then add it manually
                                yyChangeDisplay = `-${(Math.abs(yyChange) * 100).toFixed(2)}%`;
                                yyChangeStyle = {
                                  color: '#4CAF50', // Green for negative (good)
                                  fontWeight: 'bold'
                                };
                              } else {
                                // No change
                                yyChangeDisplay = `0.00%`;
                                yyChangeStyle = {
                                  color: '#757575', // Gray for no change
                                  fontWeight: 'normal'
                                };
                              }
                            }
                            
                            const content = (
                              <>
                                <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>Vintage: {filteredXLabels[y]}</div>
                                <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>As of: {filteredYLabels[x]}</div>
                                <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                                  Loss Rate: <span style={{ color: '#e63946' }}>{(value * 100).toFixed(2)}%</span>
                                </div>
                                <div style={{ marginBottom: '12px', fontWeight: 'bold' }}>
                                  Y/Y Change: <span style={yyChangeStyle}>{yyChangeDisplay}</span>
                                </div>
                                
                                <div style={{ 
                                  marginBottom: '8px', 
                                  fontWeight: 'bold',
                                  borderBottom: '1px solid rgba(255,255,255,0.3)',
                                  paddingBottom: '4px'
                                }}>
                                  Top Y/Y drivers:
                                </div>
                                
                                {(() => {
                                  const cellVintage = filteredXLabels[y];
                                  const cellMonth = parseInt(filteredYLabels[x].replace('Month ', ''));
                                  
                                  const record = parsedData.find(
                                    (row: CsvRow) => row.vintage === cellVintage && parseInt(row.as_of_month) === cellMonth
                                  );
                                  
                                  if (record) {
                                    return (
                                      <div style={{ marginLeft: '8px' }}>
                                        {record.driver1 ? (
                                          <div style={{ marginBottom: '6px' }}>
                                            <span style={{ fontWeight: 'bold', marginRight: '4px' }}>1.</span> {record.driver1}
                                          </div>
                                        ) : null}
                                        
                                        {record.driver2 ? (
                                          <div style={{ marginBottom: '6px' }}>
                                            <span style={{ fontWeight: 'bold', marginRight: '4px' }}>2.</span> {record.driver2}
                                          </div>
                                        ) : null}
                                        
                                        {record.driver3 ? (
                                          <div style={{ marginBottom: '6px' }}>
                                            <span style={{ fontWeight: 'bold', marginRight: '4px' }}>3.</span> {record.driver3}
                                          </div>
                                        ) : null}
                                        
                                        {record.driver4 ? (
                                          <div style={{ marginBottom: '6px' }}>
                                            <span style={{ fontWeight: 'bold', marginRight: '4px' }}>4.</span> {record.driver4}
                                          </div>
                                        ) : null}
                                        
                                        {record.driver5 ? (
                                          <div style={{ marginBottom: '6px' }}>
                                            <span style={{ fontWeight: 'bold', marginRight: '4px' }}>5.</span> {record.driver5}
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div style={{ marginLeft: '8px', fontStyle: 'italic', color: '#aaa' }}>
                                        No driver data available for this cell
                                      </div>
                                    );
                                  }
                                })()}
                              </>
                            );
                            showTooltip(content, e);
                          }
                        }}
                        style={{ 
                          cursor: isDragging ? "crosshair" : "pointer",
                          width: "100%", 
                          height: "100%", 
                          display: "flex", 
                          alignItems: "center", 
                          justifyContent: "center",
                          userSelect: "none" // Prevent text selection during drag
                        }}
                      >
                        {(value * 100).toFixed(2)}%
                      </div>
                    )
                  }
      
                  xLabelsStyle={() => ({
                    fontSize: "1.1rem",
                    color: "#445082",
                    transform: "rotate(90deg)", 
                    whiteSpace: "nowrap",
                    display: "block",
                    marginBottom: "20px",
                  })}
      
                  yLabelsStyle={() => ({
                    fontSize: "1.1rem",
                    color: "#445082",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "6rem",
                    height: "4rem",
                    lineHeight: "1",
                    whiteSpace: "nowrap",
                    paddingRight: "15px",
                    marginTop: "0",
                  })}
      
                  cellStyle={(x, y, _ratio) => {
                    const originalMonthIndex = originalMonthIndices[x];
                    
                    let columnRatio = 0;
                    if (columnMinMaxValues[originalMonthIndex] && 
                        columnMinMaxValues[originalMonthIndex].max !== columnMinMaxValues[originalMonthIndex].min) {
                      const value = filteredData[y][x];
                      if (value > 0) {
                        columnRatio = (value - columnMinMaxValues[originalMonthIndex].min) / 
                                     (columnMinMaxValues[originalMonthIndex].max - columnMinMaxValues[originalMonthIndex].min);
                      }
                    }
                    
                    const minColor = [235, 245, 255];
                    const maxColor = [33, 113, 181];
      
                    const r = minColor[0] + (maxColor[0] - minColor[0]) * columnRatio;
                    const g = minColor[1] + (maxColor[1] - minColor[1]) * columnRatio;
                    const b = minColor[2] + (maxColor[2] - minColor[2]) * columnRatio;
      
                    const textColor = columnRatio > 0.5 ? "white" : "black";
      
                    const isSelected = selectedCells.has(`${x},${y}`);
      
                    return {
                      background: filteredData[y][x] === 0 ? "transparent" : `rgb(${r}, ${g}, ${b})`,
                      fontSize: "1rem",
                      fontWeight: columnRatio > 0.7 ? "bold" : "normal",
                      color: filteredData[y][x] === 0 ? "transparent" : textColor,
                      width: "4rem",
                      height: "4rem",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: isSelected 
                        ? "3px solid #445082"
                        : "1px solid rgba(255, 255, 255, 0.5)",
                      borderRadius: "4px",
                      boxShadow: isSelected ? "0 0 10px rgba(68, 80, 130, 0.5)" : "none",
                      zIndex: isSelected ? 10 : 1,
                      transition: "all 0.2s ease-in-out",
                      cursor: isDragging ? "crosshair" : "pointer",
                    };
                  }}
      
                  cellHeight="4rem"
                  xLabelsPos="top"
                  yLabelsPos="left"
                  square
                />
              </div>
            </div>
          </div>
        </div>
      )}
      
      {tooltip.visible && (
        <div 
          ref={(el) => {
            if (el) {
              const rect = el.getBoundingClientRect();
              const viewportWidth = window.innerWidth;
              const viewportHeight = window.innerHeight;
              
              if (tooltip.x + 10 + rect.width > viewportWidth) {
                el.style.left = `${tooltip.x - rect.width - 10}px`;
              } else {
                el.style.left = `${tooltip.x + 10}px`;
              }
              
              if (tooltip.y + 10 + rect.height > viewportHeight) {
                el.style.top = `${tooltip.y - rect.height - 10}px`;
              } else {
                el.style.top = `${tooltip.y + 10}px`;
              }
            }
          }}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: 'fixed',
            top: tooltip.y + 10,
            left: tooltip.x + 10,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '8px 12px',
            borderRadius: '4px',
            zIndex: 1500,
            maxWidth: '300px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
            fontSize: '14px',
            pointerEvents: 'auto',
            textAlign: 'left'
          }}
        >
          <div style={{ position: 'relative', paddingRight: '20px' }}>
            <button 
              onClick={() => {
                setTooltip(prev => ({ ...prev, visible: false }));
                setSelectedCells(new Set());
              }}
              style={{
                position: 'absolute',
                top: '-5px',
                right: '-5px',
                background: 'none',
                border: 'none',
                color: 'white',
                fontSize: '16px',
                cursor: 'pointer',
                padding: '0',
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              ×
            </button>
            {tooltip.content}
          </div>
        </div>
      )}

      <div style={{ display: 'none' }}>
        <Spin spinning={isSubmitting} tip="Processing...">
          <ReportFormSection
            form={form}
            updatePayload={updatePayload}
            updateCounter={updateCounter}
            resetCounter={resetCounter}
            heatmapId={heatmapId}
          />
        </Spin>
      </div>

    </div>
  );
}